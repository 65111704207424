import { Logo } from '../../Logo'
import { Wrapper, LogoLink } from './style'

const LogoFooter = () => {
  return (
    <Wrapper>
      <LogoLink href="https://creditas.com" target="_blank" rel="noreferrer">
        <Logo />
      </LogoLink>
    </Wrapper>
  )
}

export default LogoFooter
