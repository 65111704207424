import React from 'react'
import { string, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import PreviewImage from 'images/preview.jpg'
import { Facebook } from './Facebook'
import { Twitter } from './Twitter'

const propTypes = {
  title: string.isRequired,
  slug: string.isRequired,
  description: string,
  imageToSocialMedia: object,
}

const query = graphql`
  query GetSiteData {
    site {
      siteMetadata {
        url
        name
        legalName
        social {
          twitter
        }
      }
    }
  }
`

const Metatags = ({ title, description, slug, imageToSocialMedia }) => {
  const { site } = useStaticQuery(query)
  const { siteMetadata } = site
  const standardizedPath = slug.charAt(0) === '/' ? slug : `/${slug}`
  const absoluteUrl = `${siteMetadata.url}${standardizedPath}`

  let image = `${process.env.ABSOLUTE_URL}${PreviewImage}`
  if (imageToSocialMedia)
    image = `https:${imageToSocialMedia.fluid.src.split('?')[0]}`

  return (
    <>
      <Helmet title={title}>
        <html lang="pt-BR" />
        {description && <meta name="description" content={description} />}
        <link
          rel="icon"
          href="https://assets.creditas.com/microfrontends/prod/website-assets/logotipos/1.0.0/creditas/social_icon.svg"
        />
      </Helmet>

      <Facebook
        title={title}
        description={description}
        url={absoluteUrl}
        siteName={siteMetadata.name}
        image={image}
      />
      <Twitter
        title={title}
        description={description}
        url={absoluteUrl}
        account={siteMetadata.social.twitter}
        image={image}
      />
    </>
  )
}

Metatags.propTypes = propTypes

export default Metatags
