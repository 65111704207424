import { styled, css } from '@creditas/stylitas'

const Main = styled.main`
  padding-top: 56px;

  @media (min-width: 768px) {
    padding-top: 90px;
  }
`

const customStyles = css`
  html {
    scroll-behavior: smooth;
  }
  h1 {
    font-family: Maison Neue Extended, Helvetica Now Display !important;
  }
`

export { Main, customStyles }
